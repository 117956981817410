<template>
  <div>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb text-muted justify-content-end small">
        <li class="breadcrumb-item">
          {{ $t("id") }}:
          <span class="badge bg-secondary">{{ formEdit.id }}</span>
        </li>
        <li class="breadcrumb-item">
          {{ $t("dateInsert") }}:
          <span class="badge bg-secondary" v-if="moment">
            {{ moment(formEdit.created_at).format("DD/MM/YYYY") }}
          </span>
        </li>
      </ol>
    </nav>
    <form @submit="sendEdit">

      <div class="col-12 mb-3">
        <label for="artigo" class="form-label">Em Qual Matéria esta enquete vai aparecer? <small>(Observatório Social)</small></label>
        <select
          class="form-select"  
          @change="formEdit.id_artigo = $event.target.value"        
          v-model="formEdit.id_artigo"
        >          
          <option v-for="artigos in artigoList" :key="artigos.id" :value="artigos.id" aria-describedby="artigo">{{ artigos.id }} - {{ artigos.titulo }}</option>          
        </select>    
      </div>

      <div class="col-12 mb-3">
        <label for="pergunta" class="form-label">Pergunta</label>
        <input
          type="text"
          class="form-control"
          v-model="formEdit.pergunta"
          aria-describedby="name_help"
        />
      </div>
      <div class="col-12 mb-3">
        <label for="opcaoa" class="form-label">Opção A</label>
        <input
          type="text"
          class="form-control"
          v-model="formEdit.opcaoa"
          aria-describedby="opcaoa"
        />
      </div>
      <div class="col-12 mb-3">
        <label for="opcaob" class="form-label">Opção B</label>
        <input
          type="text"
          class="form-control"
          v-model="formEdit.opcaob"
          aria-describedby="opcaob"
        />
      </div>
      <div class="col-12 mb-3">
        <label for="opcaoc" class="form-label">Opção C</label>
        <input
          type="text"
          class="form-control"
          v-model="formEdit.opcaoc"
          aria-describedby="opcaoc"
        />
      </div>
      <div class="col-12 mb-3">
        <label for="opcaod" class="form-label">Opção D</label>
        <input
          type="text"
          class="form-control"
          v-model="formEdit.opcaod"
          aria-describedby="opcaod"
        />
      </div>
      <div class="col-12 mb-3">
        <label for="opcaoe" class="form-label">Opção E</label>
        <input
          type="text"
          class="form-control"
          v-model="formEdit.opcaoe"
          aria-describedby="opcaoe"
        />
      </div>
      <div class="col-12 mb-3">
        <label for="opcaof" class="form-label">Opção F</label>
        <input
          type="text"
          class="form-control"
          v-model="formEdit.opcaof"
          aria-describedby="opcaof"
        />
      </div>
      <div class="col-12 mb-3">
        <label for="opcaog" class="form-label">Opção G</label>
        <input
          type="text"
          class="form-control"
          v-model="formEdit.opcaog"
          aria-describedby="opcaog"
        />
      </div>

      <div class="col-12 mb-3" v-if="!deleteMode">
        <label for="lang_input" class="form-label">{{ $t("status") }}</label>
        <select
          class="form-select"
          @change="formEdit.status = $event.target.value"
          v-model="formEdit.status"
        >
          <option value="1">Ativo</option>
          <option value="0">Inativo</option>
        </select>
      </div>

      <div class="d-grid gap-2">
        <button
          class="btn btn-dark bg-dark bg-gradient col-6 mx-auto"
          type="submit"
          v-if="!loading"
        >
          {{ $t("submit") }} <i class="bi bi-save"></i>
        </button>
        <button
          class="btn btn-dark bg-dark bg-gradient col-6 mx-auto"
          type="submit"
          v-if="loading"
          disabled
        >
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
          ></span>
          {{ $t("loading") }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { URL_BASE } from "../../../../../services/Commons";
import ArtigoService from "../../../../../services/artigo.service";
import EnqueteService from "../../../../../services/enquete.service";
import EnqueteHandle from "../../../../../services/handle/enquete.handle";
import moment from "moment";
export default {
  props: [
    "dataItem",
    "actionSend",
    "ActionSendFile",
    "errorServer",
    "deleteMode",
  ],
  data: () => ({
    formEdit: {},
    artigoList: [],
    urlImg: "",
    urlPdf: "",
    loading: false,
    moment: null,  
  }),
  mounted() {
    this.urlBase = URL_BASE;
    ArtigoService.getAll().then((result) => {    
      if (result.status < 400) {
        this.artigoList = result.data;
      }
    });

    this.moment = moment;
    this.moment.locale("pt-br");
    this.formEdit = this.dataItem;
  },
  methods: {
    sendEdit: function (event) {
      event.preventDefault();
      this.loading = true;
      //
      if (this.deleteMode) {
        EnqueteService.delete(this.formEdit.id).then(
          (result) => {
            console.log(result);
            this.loading = false;
            this.actionSend({
              msg: "O item foi excluído com Sucesso.",
              style: "success",
            });
          },
          (error) => {
            this.loading = false;
            this.errorServer(error);
          }
        );
        return;
      }
      EnqueteService.update(
        this.formEdit.id,
        EnqueteHandle.formToJso(this.formEdit)
      ).then(
        (result) => {
          result;
          this.loading = false;
          this.actionSend({
            msg: "O item foi alterado com Sucesso.",
            style: "success",
          });
        },
        (error) => {
          this.loading = false;
          this.errorServer(error);
        }
      );
    },
  },
};
</script>

<style>
</style>