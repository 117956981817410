<template>
  <div>
    <form @submit="sendNew">
      <div class="col-12 mb-3">
        <label for="artigo" class="form-label">Em Qual Matéria esta enquete vai aparecer? <small>(Observatório Social)</small></label>
        <select
          class="form-select"          
          v-model="formInclude.id_artigo"
        >          
          <option v-for="artigos in artigoList" :key="artigos.id" :value="artigos.id" aria-describedby="artigo">{{ artigos.id }} - {{ artigos.titulo }}</option>          
        </select>    
      </div>

      <div class="col-12 mb-3">
        <label for="pergunta" class="form-label">Pergunta</label>
        <input
          type="text"
          class="form-control"
          v-model="formInclude.pergunta"
          aria-describedby="name_help"
          placeholder="Descreva a pergunta da Enquete"
        />
      </div>
      <div class="col-12 mb-3">
        <label for="opcaoa" class="form-label">Resposta/Opção A</label>
        <input
          type="text"
          class="form-control"
          v-model="formInclude.opcaoa"
          aria-describedby="opcaoa"
          placeholder="Qual a é Resposta/Opção A ?"
        />
      </div>
      <div class="col-12 mb-3">
        <label for="opcaob" class="form-label">Resposta/Opção B</label>
        <input
          type="text"
          class="form-control"
          v-model="formInclude.opcaob"
          aria-describedby="opcaob"
          placeholder="Qual a é Resposta/Opção B ?"
        />
      </div>
      <div class="col-12 mb-3">
        <label for="opcaoc" class="form-label">Resposta/Opção C</label>
        <input
          type="text"
          class="form-control"
          v-model="formInclude.opcaoc"
          aria-describedby="opcaoc"
          placeholder="Qual a é Resposta/Opção C ?"
        />
      </div>
      <div class="col-12 mb-3">
        <label for="opcaod" class="form-label">Resposta/Opção D</label>
        <input
          type="text"
          class="form-control"
          v-model="formInclude.opcaod"
          aria-describedby="opcaod"
          placeholder="Qual a é Resposta/Opção D ?"
        />
      </div>
      <div class="col-12 mb-3">
        <label for="opcaoe" class="form-label">Resposta/Opção E</label>
        <input
          type="text"
          class="form-control"
          v-model="formInclude.opcaoe"
          aria-describedby="opcaoe"
          placeholder="Qual a é Resposta/Opção E ?"
        />
      </div>
      <div class="col-12 mb-3">
        <label for="opcaof" class="form-label">Resposta/Opção F</label>
        <input
          type="text"
          class="form-control"
          v-model="formInclude.opcaof"
          aria-describedby="opcaof"
          placeholder="Qual a é Resposta/Opção F ?"
        />
      </div>
      <div class="col-12 mb-3">
        <label for="opcaog" class="form-label">Resposta/Opção G</label>
        <input
          type="text"
          class="form-control"
          v-model="formInclude.opcaog"
          aria-describedby="opcaog"
          placeholder="Qual a é Resposta/Opção G ?"
        />
      </div>
      <div class="col-12 mb-3" v-if="!deleteMode">
        <label for="lang_input" class="form-label">Qual o Status da Enquete?</label>
        <select
          class="form-select"
          @change="formInclude.status = $event.target.value"
          v-model="formInclude.status"
        >          
          <option value="1">Ativo</option>
          <option value="0">Inativo</option>
        </select>        
      </div>
      <div class="d-grid gap-2">
        <button
          class="btn btn-success bg-success bg-gradient col-6 mx-auto"
          type="submit"
        >
          {{ $t("enviar") }} {{ $t("newone") }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { URL_BASE } from "../../../../../services/Commons";
import ArtigoService from "../../../../../services/artigo.service";
export default {
  props: ["actionSend"],
  data: () => ({
    formInclude: {},
    artigoList: [],
  }),
  mounted() {
    this.urlBase = URL_BASE;
    ArtigoService.getAll().then((result) => {    
      if (result.status < 400) {
        this.artigoList = result.data;
      }
    });

  },
  methods: {
    sendNew: function(event) {
      event.preventDefault();
      this.actionSend(this.formInclude);
    },
   
  },
};
</script>

<style></style>
